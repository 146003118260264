<template>
  <div
    id="infinite-table stick"
    :class="{ 'sticky-header-table': stickyHeader }"
  >
    <b-table
      :tbody-tr-class="rowClass"
      :sticky-header="stickyHeader"
      :id="id"
      :sort-by.sync="sortByProxy"
      :sort-desc.sync="sortDescProxy"
      :striped="striped"
      :bordered="bordered"
      :borderless="borderless"
      :outlined="outlined"
      :small="small"
      :hover="hover"
      :dark="dark"
      :fixed="fixed"
      :foot-clone="footClone"
      :no-border-collapse="noBorderCollapse"
      :items="items"
      :fields="fields"
      :head-variant="headVariant"
      :table-variant="tableVariant"
      :responsive="responsive"
      show-empty
      ref="table"
      :busy="isBusy"
      :no-local-sorting="noLocalSorting"
      @row-clicked="onRowClicked"
      @row-hovered="rowHovered"
      @row-contextmenu="onRightClick"
      @scroll.native="onScroll"
    >
      <template #table-busy>
        <div class="row m-5">
          <div class="col-12 d-flex justify-content-center">
            <GeneralLoading />
          </div>
        </div>
      </template>

      <template slot="thead-top" slot-scope="data">
        <tr v-if="tableParentFields.length">
          <template v-for="item in tableParentFields">
            <th :colspan="item.colspan" :class="item.class">
              {{ $t(item.label) }}
            </th>
          </template>
        </tr>
      </template>

      <template #head()="data">
        <div v-if="data.field.searchable">
          <span
            ><label :for="'datatable-' + data.column">{{
              data.label
            }}</label></span
          >
          <input
            v-if="data.field.searchable_type === 'string'"
            :id="'datatable-' + data.column"
            type="text"
            :value="data.field.value"
            class="form-control form-control-sm"
            @change="onChangeSearchFilter(data.column, $event.target.value)"
            placeholder="Ara"
          />
          <input
            v-if="data.field.searchable_type === 'number'"
            :id="'datatable-' + data.column"
            type="number"
            :value="data.field.value"
            class="form-control form-control-sm"
            @change="onChangeSearchFilter(data.column, $event.target.value)"
            placeholder="Ara"
          />
          <input
            v-if="data.field.searchable_type === 'date'"
            :id="'datatable-' + data.column"
            type="date"
            :value="data.field.value"
            class="form-control form-control-sm"
            @change="onChangeSearchFilter(data.column, $event.target.value)"
            placeholder="Ara"
          />
          <template v-if="data.field.searchable_type === 'select'">
            <select
              @change="onChangeSearchFilter(data.column, $event.target.value)"
              class="form-control"
            >
              <option value="">Seçiniz</option>
              <option
                :value="item.value"
                :selected="item.value === data.field.value"
                v-for="(item, index) in data.field.searchable_options"
              >
                {{ item.text }}
              </option>
            </select>
          </template>
        </div>
        <div v-else>
          <span>{{ data.label }}</span>
        </div>
      </template>
      <template #head(checkbox)="row">
        <span class="text-info">
          <b-form-checkbox
            :id="id ? id + '_check_all' : 'check_all'"
            :value="true"
            class="check_all"
            @change="checkboxAllClick"
          >
          </b-form-checkbox>
        </span>
      </template>
      <template #empty="scope">
        <div>
          <div v-if="!paginate"></div>
          <div
            class="text-center text-dark font-size-base py-4"
            v-if="!paginate || items.length === 0"
          >
            {{ $t("general.no_records_found") }}
          </div>
        </div>
      </template>
      <template #emptyfiltered="scope">
        <div class="text-center text-dark font-size-base py-4">
          {{ $t("general.no_records_found_filter") }}
        </div>
      </template>
      <template #cell(checkbox)="data">
        <b-form-checkbox
          :id="`checkbox-` + data.item.id"
          class="dt-checkbox"
          unchecked-value="not_accepted"
          value="accepted"
          @change="checkboxOneClick"
        >
        </b-form-checkbox>
      </template>
      <template #cell(is_selected)="data">
        <b-form-checkbox
          class="checkbox"
          :id="data.item.id.toString()"
          :value="data.item.id.toString()"
          v-model="selectedCheckBoxesProxy"
        ></b-form-checkbox>
        <!--        <input type="checkbox" class="checkbox" :id="data.item.id" :value="data.item.id" v-model="selectedCheckBoxesProxy">-->
      </template>
      <template #cell(selected_options)="data">
        <div>
          <custom-multi-select
            :options="tableSelected"
            :model.sync="data.item.selected_option"
            :is-inline="false"
            :isOpenMultiselect="true"
            @save="onClickMultiSelected"
            :title="null"
            :required="false"
            :name="`selected_option-${data.item.id}`"
            :input-width="`65px`"
            class="select_class"
            :item-per-row="1"
            :not-list="true"
            :max="1"
          ></custom-multi-select>
        </div>
      </template>

      <template #cell(image)="data">
        <div class="relative" style="max-width: 75px; max-height: 75px">
          <img
            v-lazy="data.item.image"
            class="img-thumbnail mx-auto d-block object-fill"
            style="max-height: 75px"
          />
          <span
            v-if="Number(data.item.is_completed) !== 1"
            class="absolute bg-danger label label-rounded label-danger rounded-lg"
            style="top: -5px; right: -10px"
            >!</span
          >
        </div>
      </template>

      <template #cell(table_image)="data">
        <div class="relative" style="max-width: 75px; max-height: 75px">
          <img
            v-lazy="data.item.table_image"
            class="img-thumbnail mx-auto d-block object-fill"
            style="max-height: 75px"
          />
        </div>
      </template>
      <template #cell(stock_card_type)="data">
        {{ stockCardTypeTrans(data.item.type) }}
      </template>
      <template #cell(photo)="data">
        <div class="relative" style="max-width: 75px; max-height: 75px">
          <img
            v-lazy="data.item.photo"
            class="img-thumbnail mx-auto d-block object-fill"
            style="max-height: 75px"
          />
        </div>
      </template>
      <template #cell(normal_image)="data">
        <img
          v-lazy="data.item.normal_image"
          class="img-thumbnail mx-auto d-block object-fill"
          style="max-height: 75px"
        />
      </template>
      <template #cell(order_export_dates)="data">
        <template v-if="data.item.order_export_dates">
          <div class="">-</div>
        </template>
        <template v-else>
          <div v-for="(date, index) in data.item.order_export_dates">
            {{ date.export_date }}
          </div>
        </template>
      </template>

      <template #cell(waybill_action_type)="data">
        <span
          v-if="Number(data.item.waybill_action_type) === 0"
          v-html="
            getIconByKey('icons.waybill.exit', {
              class:
                'waybill-transaction-icon w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
        <span
          v-else
          v-html="
            getIconByKey('icons.waybill.entry', {
              class:
                'waybill-transaction-icon w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
      </template>
      <template #cell(reject)="data">
        <span
          @click="onClickReject(data.item.id)"
          v-html="
            getIconByKey('icons.invoice.no', {
              class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
      </template>
      <template #cell(approve)="data">
        <span
          @click="onClickToApprove(data.item.id)"
          v-html="
            getIconByKey('icons.invoice.yes', {
              class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
      </template>
      <template #cell(model_house_action_type)="data">
        <span
          v-if="Number(data.item.model_house_action_type) === 0"
          v-html="
            getIconByKey('icons.waybill.exit', {
              class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
        <span
          v-else
          v-html="
            getIconByKey('icons.waybill.entry', {
              class: 'w-30px h-30px d-inline-block object-fill cursor-pointer',
            })
          "
        >
        </span>
      </template>
      <template #cell(model_house_status)="data">
        <span
          class="text-lg"
          :class="{
            'text-warning': Number(data.item.model_house_status) === 7,
            'text-navy-blue': Number(data.item.model_house_status) === 8,
            'text-navy-blue': Number(data.item.model_house_status) === 9,
            'text-primary': Number(data.item.model_house_status) === 10,
          }"
        >
          {{
            data.item.model_house_status_detail.name
              ? data.item.model_house_status_detail.name.toUpperCase()
              : ""
          }}
        </span>
      </template>

      <template #cell(invoice_status)="data">
        <span
          class="text-lg"
          :class="{
            'invoice-pre-accounting': +data.item.invoice_status_id === 15,
            'invoice-manager': Number(data.item.model_house_status) === 16,
            'invoice-accounting': Number(data.item.model_house_status) === 17,
            'invoice-approved': Number(data.item.model_house_status) === 18,
            'invoice-rejected': Number(data.item.model_house_status) === 19,
            'invoice-pre-rejected': Number(data.item.model_house_status) === 20,
          }"
        >
          {{ data.item.invoice_status }}
        </span>
      </template>

      <template #cell(status)="data">
        <span class="badge" :class="getStatusClass(data.item.status_id)">{{
          data.item.status
        }}</span>
      </template>
      <template #cell(esc_number)="data">
        <span class="d-block text-center">{{ data.item.esc_number }}</span>
        <span
          class="d-block text-center text-primary font-weight-bold"
          v-if="data.item.copied_id && +data.item.copied_id !== 0"
        >
          {{ data.item.repeated_esc_sequence + ".ESC" }}
          {{ $t("esc.repeated").toUpperCase() }}</span
        >
      </template>
      <template #cell(model_number)="data">
        <span class="d-block text-center">{{ data.item.model_number }}</span>
        <span
          class="d-block text-center text-primary font-weight-bold"
          v-if="Number(data.item.copied_count) > 0"
          >{{ $t("esc.repeated").toUpperCase() }}</span
        >
      </template>

      <template #cell(only_update)="data">
        <button
          type="button"
          class="btn btn-xs btn-outline-primary btn-pill"
          @click="onClickEdit(data.item.id)"
        >
          <i class="fas fa-pen font-size-sm"></i> {{ $t("general.edit") }}
        </button>
      </template>

      <template #cell(only_delete)="data">
        <button
          type="button"
          class="btn btn-xs btn-outline-danger ml-3 btn-pill"
          @click="onClickDelete(data.item.id)"
        >
          <i class="far fa-trash-alt"></i> {{ $t("general.delete") }}
        </button>
      </template>
      <template #cell(view)="data">
        <button
          type="button"
          class="btn btn-xs btn-outline-primary ml-3 btn-pill"
          @click="onClickView(data.item.id)"
        >
          <i class="far fa-eye"></i> {{ $t("scope.view") }}
        </button>
      </template>
      <template #cell(download)="data">
        <a href="#" @click.prevent="onDownloadButtonClick(data.item)"
          ><i class="fa fa-download"></i
        ></a>
      </template>
      <template #cell(actions)="data">
        <template v-if="!('actions' in data.item)">
          <button
            type="button"
            class="btn btn-xs btn-outline-primary btn-pill"
            @click="onClickEdit(data.item.id)"
          >
            <i class="fas fa-pen font-size-sm"></i> {{ $t("general.edit") }}
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-danger ml-3 btn-pill"
            @click="onClickDelete(data.item.id)"
          >
            <i class="far fa-trash-alt"></i> {{ $t("general.delete") }}
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-primary btn-pill"
            @click="onGetData(data.item)"
            v-if="data.item.actions && data.item.actions.get"
          >
            <i class="fas fa-pen font-size-sm"></i>
            {{ $t("general.edit") }}
          </button>
        </template>
        <template v-else>
          <div v-if="data.item.actions.convert_to_invoice">
            <button
              type="button"
              class="btn btn-xs btn-outline-danger btn-pill mb-1"
              @click="onConvertInvoice(data.item)"
              v-if="
                data.item.actions &&
                data.item.actions.convert_to_invoice &&
                +data.item.status === 1
              "
            >
              <i class="fas fa-share font-size-sm"></i>
              {{ $t("general.convert_to_invoice") }}
            </button>

            <button
              type="button"
              class="btn btn-xs btn-outline-primary btn-pill"
              @click="onGetData(data.item)"
              v-if="
                data.item.actions &&
                data.item.actions.get &&
                +data.item.status === 1
              "
            >
              <i class="fas fa-pen font-size-sm"></i>
              {{ $t("general.edit") }}
            </button>

            <span v-if="data.item.status === 2" class="text-danger"
              >Düzenleniyor</span
            >
            <span v-if="data.item.status === 3" class="text-warning">
              Muhasebeye Gönderildi</span
            >
            <span v-if="data.item.status === 4" clasS="text-success"
              >Tamamlandı</span
            >
          </div>

          <button
            type="button"
            class="btn btn-xs btn-outline-primary btn-pill"
            @click="onGetData(data.item)"
            v-if="
              data.item.actions &&
              data.item.actions.get &&
              !data.item.actions.convert_to_invoice
            "
          >
            <i class="fas fa-pen font-size-sm"></i>
            {{ $t("general.edit") }}
          </button>

          <button
            type="button"
            class="btn btn-xs btn-outline-primary btn-pill"
            @click="onClickEdit(data.item.id)"
            v-if="data.item.actions && data.item.actions.edit"
          >
            <i class="fas fa-pen font-size-sm"></i>
            {{ $t("general.edit") }}
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-danger ml-3 btn-pill"
            @click="onClickDelete(data.item.id)"
            v-if="data.item.actions && data.item.actions.delete"
          >
            <i class="far fa-trash-alt"></i>
            {{ $t("general.delete") }}
          </button>
          <button
            type="button"
            class="btn btn-xs btn-outline-warning ml-3 btn-pill"
            @click="onClickApply(data.item.id)"
            v-if="data.item.actions && data.item.actions.apply"
          >
            <i class="far fa-check-circle"></i>
            {{ $t("general.apply") }}
          </button>
        </template>
      </template>
      <template #cell(status)="data">
        <template v-if="!('status' in data.item)">
          <button
            type="button"
            class="btn btn-xs btn-pill"
            :class="{
              'btn-outline-primary': Boolean(data.item.isActive),
              'btn-outline-danger': !Boolean(data.item.isActive),
            }"
            @click="onChangeStatus(data.item.id)"
          >
            <i class="fas fa-compact-disc font-size-sm"></i>
            {{
              Boolean(data.item.isActive)
                ? $t("general.active")
                : $t("general.passive")
            }}
          </button>
        </template>
      </template>

      <template #cell(logo_user_status)="data">
        <template v-if="!('status' in data.item)">
          <button
            type="button"
            class="btn btn-xs btn-pill"
            :disabled="data.item.isActive"
            :class="{
              'btn-outline-primary': Boolean(data.item.isActive),
              'btn-outline-danger': !Boolean(data.item.isActive),
            }"
            @click="onChangeStatus(data.item.id)"
          >
            <i class="fas fa-compact-disc font-size-sm"></i>
            {{
              Boolean(data.item.isActive)
                ? $t("general.active")
                : $t("general.passive")
            }}
          </button>
        </template>
      </template>

      <template #cell(approve_status)="data">
        <template
          v-if="'approve_status' in data.item && !data.item.approve_status"
        >
          <button
            type="button"
            class="btn btn-xs btn-pill btn-outline-primary mr-2"
            @click="onApproveReject(data.item.id, true)"
          >
            {{ $t("messages.approve") }}
          </button>
          <button
            type="button"
            class="btn btn-xs btn-pill btn-outline-danger"
            @click="onApproveReject(data.item.id, false)"
          >
            {{ $t("messages.reject") }}
          </button>
        </template>
        <template v-else>
          <span class="badge-primary">{{ $t("messages.approved") }}</span>
        </template>
      </template>

      <template #cell(quantity_sent)="data">
        <template v-if="'quantity_sent' in data.item">
          <a
            :href="void 0"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'invoice.index',
                params: { order_number: data.item.order_number },
              })
            "
            target="_blank"
            >{{ data.item.quantity_sent }}</a
          >
        </template>
      </template>

      <template #cell(image_link)="data">
        <template v-if="'image_link_column' in data.item">
          <a :href="data.item.image_link" target="_blank">{{
            $t("packing_list.document_or_image")
          }}</a>
        </template>
      </template>

      <!-- START  Second Quality Report-->
       <template #cell(custom_sample_amount)="data" > 
        <table style="width:100%"> 
          <tbody>
            <tr>
              <td class="custom_column_supplier" >{{data.item.sample_amount.supplier_amount}}</td>
              <td class="custom_column_manager" >{{data.item.sample_amount.manager_amount}}</td> 
            </tr>  
          </tbody>
        </table>
      </template>
       <template #cell(custom_part_return)="data" > 
        <table style="width:100%"> 
          <tbody>
            <tr>
              <td class="custom_column_supplier" >{{data.item.part_return.supplier_amount}}</td>
              <td class="custom_column_manager" >{{data.item.part_return.manager_amount}}</td> 
            </tr>  
          </tbody>
        </table>
      </template>
       <template #cell(custom_second_quantity)="data" > 
        <table style="width:100%"> 
          <tbody>
            <tr>
              <td class="custom_column_supplier" >{{data.item.second_quantity.supplier_amount}}</td>
              <td class="custom_column_manager" >{{data.item.second_quantity.manager_amount}}</td> 
            </tr>  
          </tbody>
        </table> 
      </template>
      
      <!-- END Second Quality Report-->

      <!-- START Profit Report-->
      <template #cell(show_order_detail)="data">
        <button
          type="button"
          class="btn btn-xs btn-outline-primary ml-3 btn-pill"
          @click="onClickView(data.item.company_id)"
        >
          <i class="far fa-eye"></i>
        </button>
      </template>
      <!-- END Profit Report-->

      <!-- START ESC Report-->
      <template #cell(show_esc_report_detail)="data">
        <button
          type="button"
          class="btn btn-xs btn-outline-primary ml-3 btn-pill"
          @click="onClickView(data.item)"
        >
          <i class="far fa-eye"></i>
        </button>
      </template>
      <!-- END ESC Report-->


      
      <!--Slaughter Report START  -->
        <template #cell(size_name)="data">
          <p v-for="size in data.item.order_size_and_color_sizes" :key="size.id">{{ size.name }}</p> 
        </template>
        <template #cell(size_amount)="data">
          <p v-for="size in data.item.order_size_and_color_sizes" :key="size.id">{{size.amount}}</p> 
        </template>
      <!--Slaughter Report FINISH  -->

      <!--Fire Report START  -->
      <template #cell(slaughter_fire_ratio)="data">
          <div  :style="
            {
              'background-color':data.item.slaughter_fire_ratio_color,
              'padding':'10px 20px',
              'max-width':'120px !important',
              'min-width':'120px !important',
              'border-bottom':'1px solid grey !important'
            }">
            {{ data.item.slaughter_fire_ratio }}
          </div> 
      </template>
      <!--Fire Report FINISH  -->

      <!--SupplierDeadline Report START  -->
      <template #cell(planned_different)="data">
          <div  :style="
            {
              'background-color':data.item.planned_different_color,
              'padding':'10px 20px',
              'max-width':'120px !important',
              'min-width':'120px !important',
              'border-bottom':'1px solid grey !important'
            }">
            {{ data.item.planned_different }}
          </div> 
      </template>
      <template #cell(real_different)="data">
          <div  :style="
            {
              'background-color':data.item.real_different_color,
              'padding':'10px 20px',
              'max-width':'120px !important',
              'min-width':'120px !important',
              'border-bottom':'1px solid grey !important',
            }">
            {{ data.item.real_different }}
          </div> 
      </template>
      <!--SupplierDeadline Report FINISH  -->
      
    </b-table>
    <infinite-loading
      v-if="disabledInfiniteLoading && items.length > 10"
      @infinite="onHitBottom"
      :identifier="infiniteId"
      force-use-infinite-wrapper="true"
    >
      <span slot="spinner"></span>
      <span slot="no-more">
        <h4 v-if="page > 2">{{ $t("general.no_more_records") }}</h4>
      </span>
      <span slot="no-results">
        <h4>{{ $t("general.no_records_found") }}</h4>
      </span>
    </infinite-loading>
    <div class="w-100 px-5 mb-10">
      <Pagination
        v-if="paginate"
        :paginate="paginate"
        :page.sync="pageProxy"
        :perPage.sync="perPageProxy"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/assets/components/dataTable/Pagination";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import SelectInput from "@/assets/components/inputs/SelectInput";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import GeneralLoading from "@/assets/components/loader/GeneralLoading";
export default {
  name: "dataTable",
  props: {
    id: {
      default: "my-table",
      required: false,
    },
    packingColumnCount: {
      default: false,
      required: false,
    },
    exportColumnCount: {
      default: 2,
      required: false,
    },
    selectedCheckBoxes: {
      required: false,
      default: function () {
        return [];
      },
    },
    fields: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    tableParentFields: {
      default() {
        return [];
      },
      type: Array,
      required: false,
    },
    items: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    stickyHeader: {
      required: false,
      default: false,
    },
    title: {
      default: null,
      type: String,
    },
    infiniteId: {
      default: 1,
      type: Number,
    },
    paginate: null,
    striped: {
      type: Boolean,
      default: false,
    },
    disabledInfiniteLoading: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      required: false,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    footClone: {
      type: Boolean,
      default: false,
    },
    headVariant: {
      type: String,
      default: "none",
    },
    tableVariant: {
      type: String,
      default: "light",
    },
    noBorderCollapse: {
      type: Boolean,
      default: false,
    },
    page: {
      default: 1,
      required: false,
    },
    perPage: {
      default: 10,
      required: false,
    },
    responsive: {
      required: false,
      default: true,
    },
    noLocalSorting: {
      required: false,
      default: true,
    },
    isBusy: {
      required: false,
      default: false,
    },
  },
  components: {
    SelectInput,
    Pagination,
    CustomMultiSelect,
    SelectFilter,
    GeneralLoading,
  },
  data() {
    return {
      selectedIndexId: null,
      tableSelected: {
        1: "Satınalma Faturası kes",
        3: "Satınalma İade Faturası kes",
        4: "Toptan Satış İade Faturası kes",
        7: "Yansıtma Faturası kes",
      },
      fieldItemsData: [],
    };
  },
  computed: {
    sortByProxy: {
      get() {
        this.fieldItemsData = this.fields;
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      },
    },
    sortDescProxy: {
      get() {
        if (this.sortDesc === "desc") {
          return true;
        }
        return false;
      },
      set(value) {
        if (value) {
          this.$emit("update:sortDesc", "desc");
        } else {
          this.$emit("update:sortDesc", "asc");
        }
      },
    },
    selectedCheckBoxesProxy: {
      set(value) {
        this.$emit("update:selectedCheckBoxes", value);
      },
      get() {
        return this.selectedCheckBoxes;
      },
    },
    perPageProxy: {
      set(value) {
        this.$emit("update:perPage", value);
      },
      get() {
        return this.perPage;
      },
    },
    pageProxy: {
      set(value) {
        this.$emit("update:page", value);
      },
      get() {
        return this.page;
      },
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (0) return "table-success";
      let result ="";
      if(item.esc_creator != undefined ||item.esc_creator != ''  ){ 
        switch (item.row_compare) {
          case 0:
            result = "table-danger";
            break;
          case 1:
            result = "table-success";
            break; 
        }
      }
      return result;
    },
    checkboxAllClick(status) {
      this.$emit("checkboxAllClick", status);
    },
    checkboxOneClick(status) {
      this.$emit("checkboxOneClick", status);
    },
    getStatusClass(status) {
      let classes = [
        "badge-success",
        "badge-primary",
        "badge-warning",
        "badge-info",
        "badge-light",
        "badge-dark",
      ];
      return classes[status];
    },
    stockCardTypeTrans(type) {
      switch (type) {
        case 1:
          return this.$t("stock.stock");
        case 2:
          return this.$t("stock.purchase_service_card");
        case 3:
          return this.$t("stock.sales_service_card");
      }
    },
    rowHovered(item) {
      this.$emit("rowHovered", item);
      this.$refs.table.refresh();
    },
    onRowClicked(item, index, event) {
      this.$emit("rowClicked", item, index, event);
    },
    onRightClick(item, index, e) {
      this.$emit("rightClick", item, index, e);
    },

    onClickDelete(id) {
      this.$emit("onClickDelete", id);
    },
    onClickView(id) {
      this.$emit("onClickView", id);
    },
    onChangeStatus(id) {
      this.$emit("onChangeStatus", id);
    },
    onApproveReject(id, status) {
      this.$emit("onApproveReject", { id: id, status: status });
    },
    onClickEdit(id) {
      this.$emit("onClickEdit", id);
    },
    onGetData(item) {
      this.$emit("onGetData", item);
    },
    onConvertInvoice(item) {
      this.$emit("onConvertInvoice", item);
    },
    onClickApply(id) {
      this.$emit("onClickApply", id);
    },
    onHitBottom($state) {
      console.log("here on hit bottom");
      this.$emit("hitBottom", $state);
    },
    onClickReject(id) {
      this.$emit("onClickReject", id);
    },
    onClickToApprove(id) {
      this.$emit("onClickToApprove", id);
    },
    onDownloadButtonClick(item) {
      this.$emit("onDownloadButtonClick", item);
    },
    onChangeSearchFilter(key, value) {
      if (Array.isArray(key)) {
        this.$emit("onChangeSearchFilter", key[0], key[1]);
      } else {
        this.$emit("onChangeSearchFilter", key, value);
      }
    },
    onClickMultiSelected(payload) {
      let id = payload.name.split("-")[1];
      if (payload.newValue.length || payload.oldValue.length) {
        this.$emit("onClickMultiSelected", { id: id, value: payload.newValue });
      }
    },
    async onScroll(event) {
      if (this.disabledInfiniteLoading == false) {
        const fakeState = {
          complete: function () {},
          loaded: function () {},
          error: function () {},
          reset: function () {},
        };
        let total =
          Number(event.target.scrollTop) +
          Number(event.target.clientHeight) +
          15;
        let scrollHeight = event.target.scrollHeight;

        if (total >= scrollHeight) {
          await this.onHitBottom(fakeState);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/components/_variables.demo.scss";

*:focus {
  outline: none;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fff !important;
}

.checkbox:after,
.checkbox:before {
  z-index: 1000 !important;
}

.table_select {
  margin-left: -100px !important;
}

.invoice-pre-accounting {
  color: rgb(255, 113, 60) !important;
}

.invoice-manager {
  color: rgb(255, 118, 30) !important;
}

.invoice-accounting {
  color: rgb(255, 116, 18) !important;
}

.invoice-approved {
  color: rgb(0, 194, 0) !important;
}

.invoice-rejected {
  color: rgb(255, 44, 61) !important;
}
.sticky-header-table .b-table thead > tr > th {
  position: sticky !important;
  top: 0;
  z-index: 3;
}
.custom_column_supplier {
  width: 50%;
  padding:0px;
  margin:0px;
  text-align: center;
  background: #BEFBCC;
}
.custom_column_manager {
  width: 50%;
  padding:0px;
  margin:0px;
  text-align: center;
  background: #FED1D1;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #befbcc !important;
}
</style>
