<template>
  <div class="d-flex mb-3" :class="{'form-inline': isInline}">
    <label
        :for="name"
        class="d-inline-block col-form-label font-size-lg"
        style="width: auto !important;"
        :class="{
          'col-form-label-sm': size.toString() === 'sm',
          'col-form-label-lg': size.toString() === 'lg',
        }">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <div class="form-group d-inline-block" style="flex-grow: 1;"
         :class="{'mx-3': isInline}"
    >
      <select
          :class="{
                  'form-control-sm': size.toString() === 'sm',
                  'form-control-lg': size.toString() === 'lg',
                  'is-valid': isValid === true,
                  'is-invalid': isValid === false,
                  'is-valid': Number(modelProxy) !== 0 && modelProxy !== 'null',
                }"
          :multiple="multiple" class="form-control w-100"
          :id="name"
          v-model="modelProxy"
          :required="required"
          :style="{maxWidth: inputMaxWidth + ' !important', width: inputWidth + ' !important'}"
          :disabled="disabled">
        <option :value="index" v-for="(value, index) in values">{{ $t(value) }}</option>
      </select>
      <p class="text-danger pt-1 px-3 block" v-if="error">{{ error }}</p>
      <span class="form-text text-muted"
            :class="{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline}">{{ hint }}</span>
      <p class="text-danger pt-1 px-3 block" :class="{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': isInline}"
         v-if="error">{{ error }}</p>
    </div>
    <info-tooltip
        :helper-text="helperText"
        v-if="helperText"
    ></info-tooltip>
  </div>
</template>
<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "SelectInput",
  props: {
    title: {},
    name: {},
    values: {},
    error: {},
    model: {},
    required: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false
    },
    multiple: {
      required: false,
      default: false,
    },
    isInline: {
      required: false,
      default: true,
    },
    inputSize: {
      // 1=> small, 2 => normal, 3 => large
      required: false,
      default: 2,
    },
    isValid: {
      required: false,
      default: null,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    inputMaxWidth: {
      required: false,
      default: '150px'
    },
    inputWidth: {
      required: false,
      default: 'w-auto',
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    hint: {
      required: false,
      default: null,
    }
  },
  components: {
    InfoTooltip,
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
}

</script>

<style scoped>

</style>
